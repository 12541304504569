<template>
  <div id="withdraw-detail">
    <van-nav-bar title="提现详情" left-arrow @click-left="goLastPage" />
    <div class="flexCenter waitSvg" v-if="withdrawObj.status !== undefined">
      <svg class="icon iconReset " aria-hidden="true">
        <use :xlink:href="status[withdrawObj.status].icon"></use>
      </svg>
      <p class="waitTitle">{{status[withdrawObj.status].title}}</p>
    </div>
    <van-cell-group v-if="withdrawObj != {}">
      <van-cell title="提现方式" :value="methods[withdrawObj.method - 1]" />
      <van-cell title="提现金额" :value="withdrawObj.amount" />
      <van-cell title="订单编号" :value="withdrawObj.out_trade_no" />
      <van-cell title="提现时间" :value="withdrawObj.created_at" />
    </van-cell-group>
    <div class="fixedBottom" @click="returnWithdraw" v-if="withdrawObj.status == 0">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block>提现撤销</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      withdrawObj: {},
      methods: ["微信提现", "支付宝提现", "银行卡提现"],
      status: {
        0: {
          icon: "#icon--shalou",
          title: "提现申请已提交",
        },
        1: {
          icon: "#icon-yinhangqia-",
          title: "提现申请通过",
        },
        2: {
          icon: "#icon-chenggong",
          title: "已到账",
        },
        "-1": {
          icon: "#icon-shibai",
          title: "审核不通过",
        },
        "-2": {
          icon: "#icon-shibai",
          title: "已撤销",
        },
      },
    };
  },
  methods: {
    returnWithdraw() {
      this.$http
        .put(`/api/v1/worker/withdraw/${this.$route.params.orderId}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.$toast.success("撤销成功");
            this.$router.go(0)
          }
        });
    },
  },
  created() {
    this.$http
      .get(`/api/v1/worker/withdraw/${this.$route.params.orderId}`)
      .then((res) => {
        console.log(res);
        this.withdrawObj = res.data.result;
      });
  },
};
</script>

<style lang="less">
#withdraw-detail {
  .van-cell__value {
    flex: 2;
  }
  .waitSvg {
    margin: 3rem 0;
    flex-direction: column;
  }
  .waitTitle {
    font-size: 1.3rem;
    padding: 1rem 0;
  }
  .iconReset {
    width: 8rem;
    height: 8rem;
  }
}
</style>